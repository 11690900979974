import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Banner from 'components/ui/Banner';

const HeroBanner = () => {
  const { markdownRemark,  placeholderImage} = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "hero section" } }) {
        html
        frontmatter {
          position
          name
          linkTo
          linkText
        }
      }
      
      placeholderImage: file(relativePath: { eq: "profile.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
    }
  `);

  const heroBanner = markdownRemark.frontmatter;

  return (
    <Banner
      position={heroBanner.position}
      name={heroBanner.name}
      bio={markdownRemark.html}
      linkTo={heroBanner.linkTo}
      linkText={heroBanner.linkText}
      profilepic={placeholderImage.childImageSharp.fluid}
    />
  );
};

export default HeroBanner;
