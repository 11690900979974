import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Container from 'components/ui/Container';
import Button from 'components/ui/Button';
import TitleSection from 'components/ui/TitleSection';
import FormatHtml from 'components/utils/FormatHtml';
import Img from "gatsby-image";

import * as Styled from './styles';


const Banner = ({ position, name, bio, profilepic, linkTo, linkText }) => (
  <Styled.Banner>

    <Container section>

      <TitleSection title={name} subtitle={position} center />
      <Styled.ContentFixBioIssue><FormatHtml content={bio} /></Styled.ContentFixBioIssue>

      <Styled.Image>
        <Img fluid={profilepic} alt={position} />
      </Styled.Image>

      <Styled.CardInfo>

        <Styled.Content><FormatHtml content={bio} /></Styled.Content>

        <Link to={linkTo}>
          <Button primary>{linkText}</Button>
        </Link>

      </Styled.CardInfo>

    </Container>
  </Styled.Banner>
);

Banner.propTypes = {
  position: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  bio: PropTypes.any.isRequired,
  linkTo: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  profilepic: PropTypes.any.isRequired
};

export default Banner;
